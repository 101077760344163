import { ExpandOutlined } from "@ant-design/icons";
import {
  Space,
  Table,
  Row,
  Col,
  message,
  Drawer,
  Button,
  Modal,
  Form,
} from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProductDetails from "./Create";
import Parse from "parse";
import ProductName from "../../components/product/create/changes/ProductName";
import Restaurant from "../../components/product/create/changes/Restaurant";
import Category from "../../components/product/create/changes/Category";
import Pricing from "../../components/product/create/changes/Pricing";
import Images from "../../components/product/create/changes/Images";
import Description from "../../components/product/create/changes/Description";
import {
  MetaTitle,
  MetaTags,
  MetaDescription,
} from "../../components/product/create/changes/MetaInfo";
import Addons from "../../components/product/create/changes/Addons";
import Variants from "../../components/product/create/changes/Variants";
import { localDateTime } from "../../utils";
import styled from "styled-components";
import moment from "moment";

function ShowDiff({
  msg,
  previous = {},
  changes = {},
  categories,
  restaurants,
  createdAt,
  objectId,
  target,
  deleteFromList,
}) {
  const [open, setOpen] = useState(false); // Changed 'visible' to 'open'
  const [formPrev] = Form.useForm();
  const [formCurr] = Form.useForm();
  const [isMounted, setIsMounted] = useState(true);

  const acceptChanges = async () => {
    if (!isMounted) return;
    try {
      const log = await new Parse.Query("logs")
        .equalTo("objectId", objectId)
        .select(["objectId"])
        .first();
      if (log) {
        const product = await new Parse.Query("product")
          .select(["objectId"])
          .equalTo("objectId", target)
          .first();
        if (product) {
          for (let key in changes) {
            if (typeof changes[key]?.discount?.validity === "object") {
              if (changes[key]?.discount?.validity !== undefined) {
                changes[key].discount.validity =
                  changes[key].discount.validity?.toDate();
              }
            }
            product.set(key, changes[key]);
          }

          await product.save();
          log.set("changeRequest", false);
          await log.save();
          message.success("Changes accepted successfully");
          setOpen(false); // Changed 'visible' to 'open'
          deleteFromList(objectId);
        }
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const declineChanges = async () => {
    if (!isMounted) return;
    try {
      const log = await new Parse.Query("logs")
        .equalTo("objectId", objectId)
        .select(["objectId"])
        .first();
      if (log) {
        log.set("changeRequest", false);
        await log.save();
        message.success("Changes declined");
        setOpen(false); // Changed 'visible' to 'open'
        deleteFromList(objectId);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  try {
    useEffect(() => {
      setIsMounted(true);
      if (
        previous.price &&
        previous.price.discount &&
        previous.price.discount.validity
      ) {
        if (typeof previous.price.discount.validity !== "object") {
          previous.price.discount.validity = moment(
            previous.price.discount.validity
          );
        }
      }
      if (
        changes.price &&
        changes.price.discount &&
        changes.price.discount.validity
      ) {
        if (typeof changes.price.discount.validity !== "object") {
          changes.price.discount.validity = moment(
            changes.price.discount.validity
          );
        }
      }

      formPrev.setFieldsValue({
        ...previous,
        category: previous.category?.objectId,
        restaurant: previous.restaurant?.objectId,
      });
      formCurr.setFieldsValue({
        ...changes,
        category: changes.category?.objectId,
        restaurant: changes.restaurant?.objectId,
      });

      return () => {
        setIsMounted(false);
      };
    }, [categories, restaurants]);
  } catch (error) {
    console.log(error.message);
  }

  return (
    <div>
      <div
        style={{
          cursor: "pointer",
          background: "#fff",
          boxShadow: "0 0 5px #ddd",
          padding: "5px 10px",
          borderRadius: "5px",
          textAlign: "center",
        }}
        onClick={() => setOpen(true)} // Changed 'setVisible' to 'setOpen'
      >
        View Changes
      </div>
      <Modal
        title="Change Request"
        open={open} // Changed 'visible' to 'open'
        onOk={() => setOpen(false)} // Changed 'setVisible' to 'setOpen'
        onCancel={() => setOpen(false)} // Changed 'setVisible' to 'setOpen'
        width={1000}
        footer={null}
      >
        <Wrapper>
          <div className="flex-between">
            <h2>{msg}</h2>
            <div>{localDateTime(createdAt)}</div>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12} className="prev">
              <h4 style={{ textAlign: "center" }}>Previous State</h4>
              <Form form={formPrev} layout="vertical" className="prev">
                {Object.keys(previous).map((key, index) => {
                  if (key === "name") {
                    return <ProductName />;
                  } else if (key === "restaurant") {
                    return <Restaurant restaurants={restaurants} />;
                  } else if (key === "category") {
                    return <Category categories={categories} />;
                  } else if (key === "price") {
                    return (
                      <div>
                        <Pricing />
                        <Variants />
                      </div>
                    );
                  } else if (key === "images") {
                    return <Images image={previous[key][0]} />;
                  } else if (key === "details") {
                    return <Description />;
                  } else if (key === "meta_title") {
                    return <MetaTitle />;
                  } else if (key === "meta_tags") {
                    return <MetaTags />;
                  } else if (key === "meta_description") {
                    return <MetaDescription />;
                  } else if (key === "addons") {
                    return <Addons />;
                  }
                  return null;
                })}
              </Form>
            </Col>
            <Col span={12} className="current">
              <h4 style={{ textAlign: "center" }}>Update State</h4>
              <Form form={formCurr} layout="vertical" className="current">
                {Object.keys(changes).map((key, index) => {
                  if (key === "name") {
                    return <ProductName />;
                  } else if (key === "restaurant") {
                    return <Restaurant restaurants={restaurants} />;
                  } else if (key === "category") {
                    return <Category categories={categories} />;
                  } else if (key === "price") {
                    return (
                      <div>
                        <Pricing />
                        <Variants />
                      </div>
                    );
                  } else if (key === "images") {
                    return <Images image={changes[key]?.[0]} />;
                  } else if (key === "details") {
                    return <Description />;
                  } else if (key === "meta_title") {
                    return <MetaTitle />;
                  } else if (key === "meta_tags") {
                    return <MetaTags />;
                  } else if (key === "meta_description") {
                    return <MetaDescription />;
                  } else if (key === "addons") {
                    return <Addons />;
                  }
                  return null;
                })}
              </Form>
            </Col>
          </Row>
          <div
            className="section"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Space>
              <Button type="danger" onClick={declineChanges}>
                Decline
              </Button>
              <Button type="primary" onClick={acceptChanges}>
                Accept
              </Button>
            </Space>
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
}

const Wrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    padding: 0;
  }

  .section {
    padding: 20px;
    border: 1px solid #e6e6e6;
    background: #fff;
    border-radius: 5px;
    min-height: 100px;
    margin-bottom: 10px;
  }

  .current {
    .section {
      border: 3px solid #faad149c;
      overflow: hidden;
    }
  }

  .header {
    height: 64px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .form-title {
    font-size: 18px;
    margin-bottom: 0px;
    font-weight: 700;
  }
`;

export default function ChangeRequests() {
  const [products, setProducts] = useState({
    loading: true,
    data: [],
    count: 0,
    restaurants: [],
    categories: [],
  });
  const [productId, setProductId] = useState(null);

  const fetchProducts = async ({ limit = 100, skip = 0 } = {}) => {
    try {
      const req = await new Parse.Query("logs")
        .equalTo("type", "product")
        .equalTo("changeRequest", true)
        .select(["changes", "previous", "type", "target", "msg"])
        .ascending("createdAt")
        .limit(limit)
        .skip(skip)
        .withCount()
        .find();

      const requests = req.results.map((item) => item.toJSON());
      const catIds = [];
      const restIds = [];

      const products = await new Parse.Query("product")
        .containedIn(
          "objectId",
          requests.map((i) => i.target)
        )
        .select(["name", "images", "restaurant.name", "category.name"])
        .find();

      const byId = {};

      products.forEach((item) => {
        byId[item.id] = item.toJSON();
      });

      requests.forEach((item) => {
        if (item.target && byId[item.target]) {
          item.name = byId[item.target].name;
          item.image = byId[item.target].images?.[0];
          item.restaurant = byId[item.target].restaurant?.name;
        }

        if (item.previous && item.previous.restaurant) {
          restIds.push(item.previous.restaurant.objectId);
        }
        if (item.changes && item.changes.restaurant) {
          restIds.push(item.changes.restaurant.objectId);
        }
        if (item.previous && item.previous.category) {
          catIds.push(item.previous.category.objectId);
        }
        if (item.changes && item.changes.category) {
          catIds.push(item.changes.category.objectId);
        }
      });

      const cats = await new Parse.Query("category")
        .containedIn("objectId", catIds)
        .select(["name"])
        .limit(catIds.length)
        .find();

      const rests = await new Parse.Query("restaurant")
        .containedIn("objectId", restIds)
        .select(["name", "hub.name"])
        .limit(restIds.length)
        .find();

      setProducts((prods) => ({
        ...prods,
        loading: false,
        data: requests,
        count: req.count,
        restaurants: rests.map((item) => item.toJSON()),
        categories: cats.map((item) => item.toJSON()),
      }));
    } catch (err) {
      message.error(err.message);
    }
  };

  const deleteItem = (id) => {
    const updatedData = products.data.filter((item) => item.objectId !== id);
    setProducts((prevState) => ({
      ...prevState,
      data: updatedData,
    }));
  };

  useEffect(() => {
    fetchProducts({ limit: 100, skip: 0 });
  }, []);

  const columns = [
    {
      title: "Product",
      key: "image",
      width: "160px",
      render: ({ name, image, target }) => {
        return (
          <Space>
            <img src={image} alt="product" width="50px" height="50px" />
            <Link target="__blank" to={`/product/add?id=${target}`}>
              {name}
            </Link>
          </Space>
        );
      },
    },
    {
      title: "Show Difference",
      key: "expand",
      width: "160px",
      render: (props, record, index) => (
        <ShowDiff
          key={record.objectId} // unique key for each
          {...props}
          restaurants={products.restaurants}
          categories={products.categories}
          deleteFromList={deleteItem}
        />
      ),
    },
    {
      title: "Message",
      dataIndex: "msg",
      key: "msg",
      width: "200px",
      render: (text, { createdAt }) => (
        <div>
          <div>{text}</div>
          <div
            style={{
              fontSize: "12px",
              color: "#999",
            }}
          >
            {localDateTime(createdAt)}
          </div>
        </div>
      ),
    },
    {
      title: "Restaurant",
      dataIndex: "restaurant",
      key: "restaurant",
      width: "200px",
    },
  ];

  return (
    <div>
      <Row justify="center">
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={products.data}
            loading={products.loading}
            pagination={{
              total: products.count,
              showSizeChanger: true,
              showQuickJumper: true,
              defaultPageSize: 100,
              pageSizeOptions: ["100", "200", "300", "400", "500"],
              position: ["topLeft", "topRight"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={(pagination) => {
              fetchProducts({
                limit: pagination.pageSize,
                skip: pagination.current,
              });
            }}
            scroll={{ x: "max-content" }}
          />
        </Col>
        <Drawer
          title="Product Details"
          placement="right"
          width={1000}
          visible={!!productId}
          onClose={() => setProductId(null)}
        >
          <ProductDetails changeReqCb={deleteItem} productId={productId} />
        </Drawer>
      </Row>
    </div>
  );
}
