import { Button, Form, Input, Modal, Space } from "antd";
import axios from "axios";
import { useContext, useState } from "react";
import { UserListContext } from "../../pages/User/UserList";

const { Item } = Form;

export default function SentNotification() {
  const { selectedRows } = useContext(UserListContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "https://dev2.munchies.com.bd/api/auth/v1/user/notify",
        {
          topic: selectedRows.map((Item) => Item.id),
          title: values.title,
          body: values.body,
        }
      );
      if (res) {
        setVisible(false);
        form.resetFields();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={() => setVisible(true)} size="large" type="primary">
        Sent Notification
      </Button>
      <Modal
        title="Sent Notification"
        visible={visible}
        onOk={onSubmit}
        confirmLoading={loading}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          name="sent-notification"
          onFinish={onSubmit}
        >
          <Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size="large" placeholder="Title" />
          </Item>
          <Item
            name="body"
            label="Body"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea placeholder="Message" rows={4} />
          </Item>
          <Item>
            <Space>
              <Button onClick={() => setVisible(false)} danger>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Send
              </Button>
            </Space>
          </Item>
        </Form>
      </Modal>
    </>
  );
}
