// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import CreateRestaurant from "../pages/Restaurant/AddNew";
import AddUser from "../pages/User/AddUser";
import SectionsDashboard from "../pages/Sections/Dashboard";
import CreatePromo from "../pages/Promo/CreatePromo";
import PromoList from "../pages/Promo/PromoList";
import Categories from "../pages/Categories";
import PaymentHistoryPage from "../pages/Payments/PaymentHistoryPage";

// rider related pages
import Riders from "../pages/Rider/Riders";
import RiderDashboard from "../pages/Rider/Dashboard";

import Payments from "../pages/Payments";
import InvoiceHistory from "../pages/Payments/History";

// inventory related pages
import ProductList from "../pages/Product/ProductList";
import CreateProduct from "../pages/Product/Create";
import ProductTimeline from "../pages/Product/Timeline";
import ProductSales from "../pages/Product/ProductSales";
import Inventories from "../pages/Inventory/Inventories";
import AddPurchaseRecord from "../pages/Inventory/AddPurchaseRecord";
import UnApprovedProductList from "../pages/Product/UnApprovedList";

// Users
import UserList from "../pages/User/UserList";

// Carousel
import Carousels from "../pages/Carousel";

// ORDER
import Order from "../pages/Order";
import OrderList from "../pages/Order/OrderList";
import OrderProcessDashboard from "../pages/Order/OrderProcessor";
import OrderDetails from "../pages/Order/Details";

import Restaurants from "../pages/Restaurant/List";
import RestaurantCategories from "../pages/Restaurant/Categories";
import RestaurantDetails from "../pages/Restaurant/Details";
import RestaurantPayments from "../pages/Restaurant/Payments";
import PaymentDetails from "../pages/Restaurant/Payments/Details";
import BulkProductUpload from "../pages/Restaurant/BulkProductUpload";

import Config from "../pages/Config";

import Hub from "../pages/Hub";

import Invoice from "../pages/Payments/Invoice";

// Reports
import LogsList from "../pages/Logs/List";

import RiderOrders from "../pages/Rider/RiderOrders";

import CreateSurvey from "../pages/survey/Create";

import Attendance from "../pages/Rider/Attendance";

import DownloadPayments from "../pages/Payments/Download";

import InventoryRecords from "../pages/Product/InventoryRecords";
import SearchList from "../pages/Reports/Searches";
import UpdatePurchaseRecord from "../pages/Inventory/UpdateInventoryRecord";
import ProductChangeRequests from "../pages/Product/ChangeRequests";
import RestaurantSales from "../pages/Restaurant/Sales";
import RestaurantSalesReport from "../pages/Restaurant/SalesReport";
import OrderIssues from "../pages/Order/Issues";
import RiderRegistration from "../pages/Rider/Registration";
import RiderSettings from "../pages/Rider/Settings";
import RiderUpdate from "../pages/Rider/RiderUpdate";
import RiderProfile from "../pages/Rider/Profile";
import Cuisines from "../pages/Cuisines";
import PromotionalBanner from "../pages/PromotionalBanner";
import ArchivedOrders from "../pages/Order/ArchivedOrders";
import RidersLeaderBoard from "../pages/Rider/RidersLeaderBoard";
import WorldCupLeaderBoard from "../pages/Campaign/WorldCupLeaderBoard";
import OtpStore from "../pages/Order/Otp";
import SectionScheduling from "../pages/Sections/Scheduling";
import SlotsMap from "../pages/Sections/SlotsMap";
import SalesReport from "../pages/Product/SalesReport";
import TopSellingProducts from "../pages/Restaurant/TopSellingProducts";
import SalarySheet from "../pages/Rider/SalarySheet";
import RoleSettings from "../pages/Config/RoleSettings";
import BarCodes from "../pages/Inventory/Barcodes";
import InventoryScanning from "../pages/Inventory/Scanning";
import HubSettings from "../pages/Hub/Settings";
import Zone from "../pages/Zone";
import RestaurantPrepTimes from "../pages/Restaurant/PrepTimes";
import RestaurantHubList from "../pages/Restaurant/HubList";
import RestaurantNotification from "../pages/Restaurant/Notification";
import RestaurantNoticeBoard from "../pages/Restaurant/Noticeboard";
import RiderLiveTracking from "../pages/Rider/LiveTracking";
import RiderIndividualTracking from "../pages/Rider/IndividualTracking";
import UserHistory from "../pages/User/History";
import RiderPaymentRequest from "../pages/Rider/RiderPaymentRequest";
import ZoneControl from "../pages/Zone/ZoneControl";
import StoryPage from "../pages/story";
import UserNotice from "../pages/UserNotice/UserNotice";
import UserDashboard from "../pages/User/Dashboard";
import UserAnalyticsDashboard from "../pages/User/UserAnalyticsDashboard";
import RiderJoiningList from "../pages/Rider/RiderJoiningList";

interface Route {
  path: string;
  exact?: boolean;
  component: any;
  key: string;
}

const userRoutes: Route[] = [
  // Restaurant
  {
    path: "/campaign/world-cup-leader-board",
    component: WorldCupLeaderBoard,
    key: "world_cup_leader_board",
  },
  {
    path: "/config/role-settings",
    component: RoleSettings,
    key: "config_role_settings",
  },
  {
    path: "/report/searches",
    component: SearchList,
    key: "searches",
  },
  {
    path: "/report/order-analytics",
    exact: true,
    component: Order,
    key: "order_analytics",
  },
  {
    path: "/report/product-category-sales",
    exact: true,
    component: SalesReport,
    key: "product_category_sales_report",
  },
  {
    path: "/report/inventory",
    exact: true,
    component: InventoryRecords,
    key: "inventory_report",
  },
  {
    path: "/report/restaurant-sales",
    exact: true,
    component: RestaurantSales,
    key: "restaurant_sales_report",
  },
  {
    path: "/report/restaurant-prep-times",
    exact: true,
    component: RestaurantPrepTimes,
    key: "restaurant_prep_times",
  },
  {
    path: "/survey/create",
    component: CreateSurvey,
    key: "add_survey",
  },
  {
    path: "/restaurant/list",
    exact: true,
    component: Restaurants,
    key: "restaurant_list",
  },
  {
    path: "/restaurant/notification",
    exact: true,
    component: RestaurantNotification,
    key: "restaurant_notification",
  },
  {
    path: "/restaurant/noticeboard",
    exact: true,
    component: RestaurantNoticeBoard,
    key: "restaurant_noticeboard",
  },
  {
    path: "/restaurant/:id/top-selling-products",
    exact: true,
    component: TopSellingProducts,
    key: "restaurant_top_selling_products",
  },

  {
    path: "/restaurant/:id/sales-report",
    exact: true,
    component: RestaurantSalesReport,
    key: "restaurant_sales_report",
  },
  {
    path: "/restaurant/:id/categories",
    exact: true,
    component: RestaurantCategories,
    key: "restaurant_categories",
  },
  {
    path: "/restaurant/add",
    exact: true,
    component: CreateRestaurant,
    key: "add_restaurant",
  },
  {
    path: "/restaurant/:id/details",
    exact: true,
    component: RestaurantDetails,
    key: "restaurant_details",
  },
  {
    path: "/restaurant/:restaurantId/payments/:paymentId",
    exact: true,
    component: PaymentDetails,
    key: "restaurant_payment_details",
  },
  {
    path: "/restaurant/:restaurantId/payments",
    exact: true,
    component: RestaurantPayments,
    key: "restaurant_payments",
  },
  {
    path: "/restaurant/:restaurantId/product-upload",
    exact: true,
    component: BulkProductUpload,
    key: "restaurant_product_upload",
  },
  {
    path: "/restaurant/hub-list",
    exact: true,
    component: RestaurantHubList,
    key: "restaurant_hub_list",
  },

  /// Sections ...
  {
    path: "/sections/dashboard",
    exact: true,
    component: SectionsDashboard,
    key: "sections",
  },
  {
    path: "/sections/scheduling",
    exact: true,
    component: SectionScheduling,
    key: "section_scheduling",
  },
  {
    path: "/sections/slots-map",
    exact: true,
    component: SlotsMap,
    key: "section_slots_map",
  },
  {
    path: "/cuisines",
    exact: true,
    component: Cuisines,
    key: "cuisines",
  },

  // Rider
  {
    path: "/rider/joining",
    exact: true,
    component: RiderJoiningList,
    key: "rider_joining",
  },
  {
    path: "/rider/dashboard",
    exact: true,
    component: RiderDashboard,
    key: "rider_dashboard",
  },
  {
    path: "/rider/salary-sheet",
    exact: true,
    component: SalarySheet,
    key: "rider_salary_sheet",
  },
  {
    path: "/rider/leaderboard",
    exact: true,
    component: RidersLeaderBoard,
    key: "rider_leaderboard",
  },
  {
    path: "/rider/registration",
    exact: true,
    component: RiderRegistration,
    key: "rider_registration",
  },
  {
    path: "/rider/update/:id",
    exact: true,
    component: RiderUpdate,
    key: "rider_update",
  },
  {
    path: "/rider/profile/:id",
    exact: true,
    component: RiderProfile,
    key: "rider_profile",
  },
  {
    path: "/rider/dashboard/:riderId",
    exact: true,
    component: RiderOrders,
    key: "rider_dashboard_orders",
  },
  {
    path: "/rider/list",
    exact: true,
    component: Riders,
    key: "rider_list",
  },
  {
    path: "/rider/settings",
    exact: true,
    component: RiderSettings,
    key: "rider_settings",
  },
  {
    path: "/rider/list/:id/attendance",
    exact: true,
    component: Attendance,
    key: "rider_attendance",
  },
  {
    path: "/rider/tracking",
    exact: true,
    component: RiderLiveTracking,
    key: "rider_tracking",
  },
  {
    path: "/rider/tracking/:id",
    exact: true,
    component: RiderIndividualTracking,
    key: "rider_individual_tracking",
  },
  {
    path: "/rider/payment_request",
    exact: true,
    component: RiderPaymentRequest,
    key: "rider_payment_request",
  },

  // invoice
  {
    path: "/invoice/:invoiceId",
    exact: true,
    component: Invoice,
    key: "invoice_details",
  },
  {
    path: "/payments",
    exact: true,
    component: Payments,
    key: "payments",
  },
  {
    path: "/payments/history",
    exact: true,
    component: InvoiceHistory,
    key: "invoice_history",
  },
  {
    path: "/payments/download",
    component: DownloadPayments,
    key: "download_payments",
  },

  // Product
  {
    path: "/product/categories",
    exact: true,
    component: Categories,
    key: "categories",
  },
  {
    path: "/payments/:resturentId/history",
    exact: true,
    component: PaymentHistoryPage,
    key: "payment_history",
  },

  {
    path: "/product/add",
    exact: true,
    component: CreateProduct,
    key: "add_product",
  },
  {
    path: "/product/list",
    exact: true,
    component: ProductList,
    key: "product_list",
  },
  {
    path: "/product/approval",
    exact: true,
    component: UnApprovedProductList,
    key: "pending_product_approval",
  },
  {
    path: "/product/change-requests",
    exact: true,
    component: ProductChangeRequests,
    key: "product_change_request",
  },
  {
    path: "/product/:id/sales",
    exact: true,
    component: ProductSales,
    key: "product_sales",
  },
  {
    path: "/product/:id/timeline",
    exact: true,
    component: ProductTimeline,
    key: "product_timeline",
  },
  // inventory
  {
    path: "/inventory/add",
    exact: true,
    component: AddPurchaseRecord,
    key: "add_inventory",
  },
  {
    path: "/inventory/barcodes",
    exact: true,
    component: BarCodes,
    key: "inventory_barcodes",
  },
  {
    path: "/story",
    exact: true,
    component: StoryPage,
    key: "story",
  },
  {
    path: "/inventory/scanning",
    exact: true,
    component: InventoryScanning,
    key: "inventory_scanning",
  },
  {
    path: "/inventory/:id/update",
    exact: true,
    component: UpdatePurchaseRecord,
    key: "update_inventory",
  },
  {
    path: "/inventory/list",
    exact: true,
    component: Inventories,
    key: "inventory_history",
  },

  // Order

  {
    path: "/order/list",
    exact: true,
    component: OrderList,
    key: "order_list",
  },
  {
    path: "/order/processor",
    component: OrderProcessDashboard,
    key: "order_processor",
  },
  {
    path: "/order/issues",
    component: OrderIssues,
    key: "order_issues",
  },
  {
    path: "/order/list/:id",
    component: OrderDetails,
    key: "order_details",
  },
  {
    path: "/order/archived",
    component: ArchivedOrders,
    key: "archived_orders",
  },

  {
    path: "/config/logs",
    component: LogsList,
    key: "logs",
  },
  {
    path: "/config",
    exact: true,
    component: Config,
    key: "config_general",
  },
  // Hub
  { path: "/hub", component: Hub, key: "hub_list" },
  { path: "/hub/settings", component: HubSettings, key: "hub_list" },
  { path: "/hub/geo-areas", component: Zone, key: "hub_geo_areas" },
  {
    path: "/hub/zone-control",
    component: ZoneControl,
    key: "hub_zone_control",
  },
  {
    path: "/promo/list",
    component: PromoList,
    key: "promo_list",
  },
  {
    path: "/promo/add",
    component: CreatePromo,
    key: "add_promo",
  },

  // Carousel
  {
    path: "/carousel",
    component: Carousels,
    key: "carousel",
  },
  {
    path: "/promotional-banner",
    component: PromotionalBanner,
    key: "promotional_banner",
  },

  // Users
  {
    path: "/user/add",
    component: AddUser,
    key: "add_user",
  },
  {
    path: "/user/otp",
    component: OtpStore,
    key: "otp_store",
  },
  {
    path: "/user/list",
    component: UserList,
    key: "user_list",
  },
  {
    path: "/user/notice",
    component: UserNotice,
    key: "user_notice",
  },
  {
    path: "/user/history",
    component: UserHistory,
    key: "user_history",
  },
  {
    path: "/user/dashboard",
    component: UserDashboard,
    key: "user_dashboard",
  },

  {
    path: "/user/analytics-dashboard",
    exact: true,
    component: UserAnalyticsDashboard,
    key: "user_analytics_dashboard",
  },

  // dashboard
  { path: "/", exact: true, component: Dashboard, key: "dashboard" },
  {
    path: "/dashboard",
    component: Dashboard,
    key: "dashboard",
  },
];

const authRoutes = [{ path: "/", component: Login }];

export { userRoutes, authRoutes };
