import { Card, Table, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Parse from "parse";

const RiderJoiningList = (): JSX.Element => {
  const [riders, setRiders] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRiders = async () => {
      try {
        const response = await Parse.Cloud.run("riderRegistration");
        setRiders(response);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred.");
        }
      }
    };

    fetchRiders();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => (
        <Link to={`/rider/joining?riderId=${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  return (
    <Card title="Rider Joining List">
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Space style={{ width: "100%" }} direction="vertical">
        <Table columns={columns} dataSource={riders} rowKey="id" />
      </Space>
    </Card>
  );
};

export default RiderJoiningList;
