import { useContext, useState } from "react";
import { Button, Col, Drawer, Popover, Row, Space, Table } from "antd";
import { RidersActivity } from "../../../pages/Rider/Attendance";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ExpandOutlined } from "@ant-design/icons";
import OrderDetails from "../../../pages/Order/Details";

const Statistics = () => {
  const { data, activeDate, dayWiseOrders } = useContext(RidersActivity);

  const Item = ({ title, value }) => {
    return (
      <Col span={24} md={8} lg={6}>
        <div className="s-item">
          <h4 className="title">{title}</h4>
          <p className="value">{value}</p>
        </div>
      </Col>
    );
  };

  const getCollection = (orders) => {
    let collection = 0;
    orders?.forEach((order) => {
      if (order.payment_method === "cod") {
        collection += order.charge.total;
      }
    });

    return collection;
  };

  const orders = activeDate ? dayWiseOrders(activeDate) : data?.orders;

  return (
    <StatisticsWrapper gutter={[16, 16]}>
      {activeDate && (
        <Item title="Date" value={new Date(activeDate).toLocaleDateString()} />
      )}
      <Item title="Cash Collection" value={getCollection(orders) + " tk"} />
      <Item title="Total Delivered" value={orders.length} />
    </StatisticsWrapper>
  );
};

const StatisticsWrapper = styled(Row)`
  margin-bottom: 20px;

  .s-item {
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .title,
    .value {
      margin-bottom: 0;
    }
  }
`;

export default function Orders() {
  const { activeDate, dayWiseOrders, data } = useContext(RidersActivity);
  const [orderId, setOrderId] = useState(null);

  const ordersColumn = [
    {
      title: "ID",
      dataIndex: "objectId",
      key: "objectId",
      render: (id) => {
        return (
          <Space>
            <Link to={`/order/list/${id}/details`}>{id}</Link>
            <Button
              size="small"
              style={{ border: "none" }}
              onClick={() => setOrderId(id)}
              icon={<ExpandOutlined />}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "charge",
      key: "total",
      width: "100px",
      render: (charge) => charge.total,
    },
    {
      title: "Payment method",
      dataIndex: "payment_method",
      key: "payment_method",
      width: "120px",
    },
    {
      title: "Delivery Charge",
      dataIndex: "charge",
      key: "delivery_charge",
      width: "120px",
      render: (charge) => charge.delivery_charge,
    },
    {
      title: "Hub",
      dataIndex: "hub",
      key: "hub",
    },
    {
      title: "Delivery Area",
      dataIndex: "customer_area",
      key: "customer_area",
      filters: data ? [
        ...new Set(data.orders.map((order) => order.customer_area)),
      ].map((area)=> ({text: area, value:area}))
      : [],
      onFilter: (value, record) => record.customer_area === value,
    },
    {
      title: "Delivered at",
      dataIndex: "completedAt",
      key: "completedAt",
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: "Estimated Time",
      dataIndex: "riderEdt",
      key: "riderEdt",
      render: (time) => (
        <Popover content="Estimated Delivery Time">
          {time ? time + " min" : ""}
        </Popover>
      ),
    },
    {
      title: "Ready to Picked",
      dataIndex: "readyToPicked",
      key: "readyToPicked",
      render: (time) => (
        <Popover content="Ready to Picked time">
          {time ? time + " min" : ""}
        </Popover>
      ),
    },
    {
      title: "Picked to Delivered",
      dataIndex: "pickedToDelivered",
      key: "pickedToDelivered",
      render: (time) => (
        <Popover content="Picked to Delivered time">
          {time ? time + " min" : ""}
        </Popover>
      ),
    },
  ];

  return data ? (
    <Col span={24}>
      <Statistics />
      <Table
        columns={ordersColumn}
        dataSource={activeDate ? dayWiseOrders(activeDate) : data.orders}
        pagination={false}
        rowClassName={(record) => {
          const isLate = record.riderEdt + 5 < record.pickedToDelivered;
          return isLate ? "late" : "";
        }}
        scroll={{ x: "max-content" }}
      />
      <Drawer
        title="Order Details"
        placement="right"
        visible={Boolean(orderId)}
        onClose={() => setOrderId(null)}
        width={1000}
      >
        {orderId && <OrderDetails orderId={orderId} />}
      </Drawer>
    </Col>
  ) : null;
}
